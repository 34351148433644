import React from 'react'
import styles from 'components/shared/image-input.module.scss'
import cn from 'classnames'

export default function ImageInput(props) {
    const { src, alt, handleImg } = props
    return (
        <div className={cn('team-image-input-comp', styles.imageInputComp, props.contextStyles)}>
            {src && (
                <div className={cn('team-logo', styles.logo)}>
                    <img src={src} alt={alt} />
                </div>
            )}

            <div className="team-input-group">
                <label className={styles.header} htmlFor="team_team_image">
                    {props.label}
                </label>
                <input
                    id="team_team_image"
                    //ref={(el) => { imgInput = el }}
                    //className={cn('answer-img', styles.input)}
                    name={props.name}
                    type="file"
                    onChange={handleImg}
                />
            </div>
        </div>
    )
}
