import React from 'react'
import cn from 'classnames'
import SlackIcon from 'public/slack-icon.png'
import ExtensionIcon from 'public/chrome-icon.png'
import Gravatar from 'react-gravatar'
import useStore from 'state/knovStore'
import WithTooltip from 'components/shared/WithTooltip'
import SpaceIcon from 'components/spaces/SpaceIcon'
import styles from './sidebar.module.scss'
import { Link } from 'react-router-dom'
import { clearAccessTokens } from 'api/accessTokenMgmt'
import { useNavigate } from 'react-router-dom'

export default React.forwardRef(function Sidebar(props, ref) {
    const navigate = useNavigate()
    const knovigatorSpace = useStore(state => state.knovigatorSpace)
    const spaceNotiCounts = useStore(state => state.spaceNotiCounts)

    const activeSpaceId = useStore(state => state.activeSpaceId)
    const setActiveSpace = useStore(state => state.setActiveSpace)
    const userSpaces = useStore(state => state.userSpaces)

    const user = gon.currentUser

    const isUserSignedIn = () => !!user

    const showSignup = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        showSignupModal()
    }

    const showLogin = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        showLoginModal()
    }

    const signOut = () => {
        try {
            clearAccessTokens()
        } catch (e) {
            console.error(e)
        }
    }

    const currentSpace = userSpaces?.find(s => s.id == activeSpaceId) || knovigatorSpace

    return (
        <>
            <div
                id={'sidebar'}
                ref={ref}
                className={cn(styles.sidebarPanel, 'h100', props.showSidebar && styles.showSidebar)}
                tabIndex={0}
            >
                <div className={styles.currentSpaceContainer}>
                    {currentSpace && (
                        <SpaceItem
                            space={currentSpace}
                            contextStyles={styles.currentSpace}
                            spaceIconStyles={cn(styles.spaceIcon, styles.currentSpaceIcon)}
                            showSettings={currentSpace.owner_id === gon.currentUser?.id}
                            navigateSpaceSettings={() => {
                                location.href = `/spaces/${currentSpace.id}/settings`
                            }}
                        />
                    )}
                </div>

                {false && user && (
                    <div className={styles.spacePanelContainer}>
                        <div className={styles.spacePanelHeader}>Your Spaces</div>

                        <div className={styles.spaceListContainer}>
                            {userSpaces?.map(space => {
                                const spaceId = space.id
                                const spaceNotiCount = spaceNotiCounts?.[space.id]
                                return (
                                    <SpaceItem
                                        key={space.id}
                                        space={space}
                                        spaceNotiCount={spaceNotiCount}
                                        isChosen={spaceId === activeSpaceId}
                                        spaceIconStyles={styles.spaceIcon}
                                        navigateSpace={() => {
                                            useStore.getState().setShowSidebar(false)
                                            if (spaceId !== currentSpace.id)
                                                setActiveSpace(spaceId /*, space */) // TODO bring this back when subdomain reloads are brought back to access subdomain safe name.
                                        }}
                                    />
                                )
                            })}
                        </div>

                        <div className={styles.spaceButtonContainer}>
                            <CreateSpace />
                        </div>
                    </div>
                )}

                <div className={styles.sidebarNavigation}>
                    <div className={styles.gettingStarted}>
                        <div className={styles.sidebarLink}>
                            <a
                                href="https://home.treechat.ai/quest/cd4069e6-0db7-4acd-947c-2d70310ad9f9"
                                className={styles.knovLink}
                            >
                                <i className="fa fa-question" />
                                Getting Started
                            </a>
                        </div>
                    </div>

                    {isUserSignedIn() && (
                        <div className={styles.sidebarLink}>
                            <a href="/profile" className={styles.knovLink}>
                                <Gravatar
                                    className={cn(styles.sidebarIcon, styles.userIcon)}
                                    width={24}
                                    height={24}
                                    md5={gon.currentUser.md5_email}
                                />
                                Settings
                            </a>
                        </div>
                    )}

                    <div className={styles.sidebarLink}>
                        <a
                            href="https://chrome.google.com/webstore/detail/lmkbllbbdmebbpmjkajffmgjfbapcnlh"
                            className={styles.knovLink}
                        >
                            <img src={ExtensionIcon} className={styles.sidebarIcon} />
                            Extension
                        </a>
                    </div>

                    {false && isUserSignedIn() && (
                        <div className={styles.sidebarLink}>
                            <a href="/community" className={styles.knovLink}>
                                <img
                                    src={SlackIcon}
                                    className={cn(styles.sidebarIcon, styles.slackIcon)}
                                />
                                Community
                            </a>
                        </div>
                    )}
                    {isUserSignedIn() && (
                        <div className={styles.sidebarLink}>
                            <div
                                className={styles.knovLink}
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    return fetch('/quests/export', {
                                        method: 'POST',
                                    }).then(() => {
                                        window.alert(
                                            'Your export has started. We will email you a download link when it finishes.',
                                        )
                                    })
                                }}
                            >
                                <i className="fa fa-download" />
                                Export All
                            </div>
                        </div>
                    )}
                    {isUserSignedIn() ? (
                        <div className={styles.sidebarLink}>
                            <Link
                                to="/login"
                                rel="nofollow"
                                className={styles.knovLink}
                                onClick={signOut}
                            >
                                <i className="fa fa-sign-out" />
                                Log out
                            </Link>
                        </div>
                    ) : (
                        <>
                            <div className={styles.sidebarLink}>
                                <a
                                    href="/login"
                                    rel="nofollow"
                                    className={styles.knovLink}
                                    //onClick={showLogin}
                                >
                                    <i className="fa fa-sign-in" />
                                    Log In
                                </a>
                            </div>

                            <div className={styles.sidebarLink}>
                                <a
                                    href="/signup"
                                    rel="nofollow"
                                    className={styles.knovLink}
                                    //onClick={showSignup}
                                >
                                    <i className="fa fa-user" />
                                    Sign up
                                </a>
                            </div>
                        </>
                    )}

                    {/*isUserSignedIn() &&
                        knovigatorSpace?.standing_space_invitations?.[0]?.url &&
                        knovigatorSpace?.id !== activeSpaceId && (
                            <div className={styles.knovigatorInvitation}>
                                <div className={styles.sidebarLink}>
                                    <a
                                        href={`${knovigatorSpace?.standing_space_invitations?.[0]?.url}`}
                                        className={cn(styles.knovLink, styles.spaceLink)}
                                    >
                                        <SpaceIcon
                                            space={knovigatorSpace}
                                            contextStyles={styles.knovSpaceIcon}
                                        />
                                        <span className={styles.spaceName}>
                                            Join Knovigator Space
                                        </span>
                                    </a>
                                </div>
                            </div>
                        )*/}
                </div>
            </div>
        </>
    )
})

function SpaceItem(props) {
    const space = props.space
    const spaceNotiCount = props.spaceNotiCount

    const onSpace = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (props.navigateSpace) props.navigateSpace()
    }

    const onSettings = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (props.navigateSpaceSettings) props.navigateSpaceSettings()
    }

    const chosenStyles = props.isChosen ? styles.chosen : null

    return (
        <div
            className={cn(styles.spaceListItemComp, chosenStyles, props.contextStyles)}
            onClick={onSpace}
        >
            <div className={styles.spaceIconContainer}>
                <SpaceIcon space={space} contextStyles={props.spaceIconStyles} />
            </div>

            <div className={styles.spaceName}>{space?.name}</div>

            <div className={styles.rightIndicators}>
                {props.showSettings && (
                    <div className={styles.spaceSettings} onClick={onSettings}>
                        <i className="fa fa-cog" />
                    </div>
                )}

                {spaceNotiCount > 0 && (
                    <div className={styles.spaceNotiCountContainer}>
                        <div className={styles.spaceNotiCount}>{spaceNotiCount}</div>
                    </div>
                )}
            </div>
        </div>
    )
}

function CreateSpace(props) {
    const openNewSpaceModal = useStore(state => state.actions.modalActions.openNewSpaceModal)

    return (
        <WithTooltip tip={'Create new space'}>
            <div className={styles.spaceButtonComp} onClick={openNewSpaceModal}>
                <i className="fa fa-plus" />
                <span className={styles.spaceLabel}>New Space</span>
            </div>
        </WithTooltip>
    )
}
