import React, { useState } from 'react'
import ImageInput from 'components/shared/ImageInput'
import api from 'api/api'
import styles from 'components/modals/image-update.module.scss'

export default function UserImageUpdate({ modalParams, closeModal }) {
    const { user } = modalParams

    const [{ alt, src }, setImg] = useState({
        src: user.user_image_url ? user.user_image_url : '',
        alt: 'No Image',
    })

    const handleSubmit = async ev => {
        ev.preventDefault()
        ev.stopPropagation()

        const form = new FormData(ev.target)
        closeModal()
        const data = await api.updateUser(user.id, form)
        if (data?.user) modalParams.callback(data.user)
    }

    const handleImage = ev => {
        if (ev.target.files[0]) {
            setImg({
                src: URL.createObjectURL(ev.target.files[0]),
                alt: ev.target.files[0].name,
            })
        }
    }

    return (
        <form method="PATCH" onSubmit={handleSubmit} encType="multipart/form-data">
            {/*<input type="hidden" name="authenticity_token" value={gon.formToken} />*/}

            <ImageInput
                src={src}
                alt={alt}
                name="user_image"
                handleImg={handleImage}
                label="Profile Picture"
                contextStyles={styles.userImageInput}
            />

            <div className="btn-container">
                <button className="btn btn-primary reload-btn" type="submit">
                    Submit
                </button>
            </div>
        </form>
    )
}
